import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { BlogData } from './Blog';
import sanityClient from '../../client';
import PortableText from '../../components/portableText/PortableText';
import { urlFor } from '../../image-url-builder';

const Blogpost = () => {
  const { slug } = useParams();
  const [blogPost, setBlogPost] = useState({} as BlogData);

  useEffect(() => {
    sanityClient
      .fetch<BlogData>(
        `*[_type == 'blogPost' && slug.current == '${slug}']{title, 'slug': slug.current, image, body, author->{ title }, _updatedAt }[0]`
      )
      .then((data) => {
        setBlogPost(data);
      })
      .catch(console.error);
  }, [slug]);

  if (!blogPost) return null;

  const { title, author, _updatedAt } = blogPost;

  return (
    <div>
      <div className="w-full max-w-7xl mx-auto mt-20 flex flex-col gap-y-6 lg:gap-y-12 mb-12">
        <div className="w-full p-4 xl:p-0">
          <h1 className="text-7xl lg:text-5xl xl:text-8xl text-gray-800 leading-none underline decoration-brand-yellow">
            {title ?? ''}
          </h1>
          <p className="text-gray-500">
            {author?.title} - {new Date(_updatedAt).toLocaleString()}
          </p>
        </div>
        <div className="flex flex-col gap-y-8">
          {blogPost.image && (
            <img
              className="h-64 w-full object-cover p-4 xl:p-0"
              key={blogPost.slug}
              src={urlFor(blogPost?.image).url()}
              alt={blogPost.title}
            />
          )}
          <div className="flex flex-col gap-y-4 p-4 xl:p-0">
            <PortableText value={blogPost.body} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blogpost;
