import { useParams } from 'react-router';
import { ProjectData } from './Projects';
import { useEffect, useState } from 'react';
import sanityClient from '../../client';
import PortableText from '../../components/portableText/PortableText';
import { urlFor } from '../../image-url-builder';

const Project = () => {
  const { projectId } = useParams();
  const [project, setProject] = useState({} as ProjectData);

  useEffect(() => {
    sanityClient
      .fetch<ProjectData>(
        `*[_type == 'project' && slug.current == '${projectId}']{title, 'slug': slug.current, 'image': company->logo.asset->, body, _updatedAt }[0]`
      )
      .then((data) => {
        setProject(data);
      })
      .catch(console.error);
  }, [projectId]);

  if (!project) return null;

  const { title, _updatedAt } = project;

  return (
    <div>
      <div className="w-full max-w-7xl mx-auto mt-20 flex flex-col gap-y-6 lg:gap-y-12 mb-12">
        <div className="w-full p-4 xl:p-0">
          <h1 className="text-7xl lg:text-5xl xl:text-8xl text-gray-800 leading-none underline decoration-brand-yellow">
            {title ?? ''}
          </h1>
          <p className="text-gray-500">
            {new Date(_updatedAt).toLocaleString()}
          </p>
        </div>
        <div className="flex flex-col gap-y-8">
          {project.image && (
            <img
              className="h-64 w-full object-contain p-4 xl:p-0"
              key={project.slug}
              src={urlFor(project?.image).url()}
              alt={project.title}
            />
          )}
          <div className="flex flex-col gap-y-4 p-4 xl:p-0">
            <PortableText value={project.body} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Project;
