const settingsQuery = `
    *[_id == "settings"] {
        header {
        links[] {
            title,
            link,
            _key
        },
        'logo': logo.asset-> {
            url
        }
        },
        footer {
        links[] {
            title,
            link,
            _key
        }
        }
    }[0]
`;

export default settingsQuery;
