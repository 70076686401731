import {
  PortableText as PortableTextReact,
  PortableTextProps,
} from '@portabletext/react';
import PortableTextComponents from './PortableTextComponents';

const PortableText = ({ value }: { value: PortableTextProps['value'] }) => {
  return (
    <PortableTextReact value={value} components={PortableTextComponents} />
  );
};

export default PortableText;
