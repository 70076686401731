import { useState, useEffect } from 'react';

import Header from './components/header/Header';
import Footer from './components/footer/Footer';

import sanityClient from './client';
import settingsQuery from './queries/settingsQuery';
import Home from './pages/home';
import { Route, Routes } from 'react-router-dom';
import Projects from './pages/home/Projects';
import Blog from './pages/home/Blog';
import Project from './pages/home/Project';
import Blogpost from './pages/home/Blogpost';
import Catch from './pages/home/Catch';

export type Settings = {
  header: {
    logo: {
      url: string;
    };
    links: {
      title: string;
      link: string;
      _key: string;
    }[];
  };
  footer: {
    links: {
      title: string;
      link: string;
      _key: string;
    }[];
  };
};

function App() {
  const [settings, setSettings] = useState({} as Settings);

  useEffect(() => {
    sanityClient
      .fetch<Settings>(settingsQuery)
      .then((data) => setSettings(data))
      .catch(console.error);
  }, []);

  if (!settings) return null;

  return (
    <div>
      <div className="pt-16 min-h-screen">
        <Header settings={settings.header} />
        <Routes>
          <Route element={<Home />} path="/" />
          <Route element={<Projects />} path="/projects" />
          <Route element={<Project />} path="/projects/:projectId" />
          <Route element={<Blog />} path="/blog" />
          <Route element={<Blogpost />} path="/blog/:slug" />

          <Route element={<Catch />} path="/:slug" />
        </Routes>
      </div>
      <Footer settings={settings.footer} />
    </div>
  );
}

export default App;
