import { useRef, useState } from 'react';
import { Settings } from '../../App';
import Logo from '../../assets/ShiftLogo_ButtonOnly.png';
import emailjs from '@emailjs/browser';

type FooterProps = {
  settings: Settings['footer'];
};

const Footer = ({ settings }: FooterProps) => {
  const [status, setStatus] = useState('');

  const year = new Date().getFullYear();
  const form = useRef<HTMLFormElement>({} as HTMLFormElement);

  const sendEmail = (e: any) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE ?? '',
        process.env.REACT_APP_EMAILJS_TEMPLATE ?? '',
        form.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (result: any) => {
          setStatus('success');
        },
        (error: any) => {
          setStatus('error');
        }
      );
  };

  if (!settings) return null;

  return (
    <div
      className="w-full bg-brand-yellow flex justify-center items-center p-8 overflow-hidden"
      id="footer"
    >
      <div className="max-w-7xl w-full h-full relative flex flex-col gap-y-8">
        <img
          src={Logo}
          alt="Shift Innovatie"
          className="absolute rotate-45 -bottom-1/3 right-0 opacity-30 h-full object-cover"
        />
        <div className="flex flex-col lg:flex-row gap-y-8 w-full flex-1 gap-x-8 items-center lg:items-start z-10">
          <div className="w-full max-w-sm">
            <h1 className="text-3xl font-bold mb-4 text-gray-800">Contact</h1>
            {status === '' ? (
              <form
                ref={form}
                onSubmit={sendEmail}
                className="flex flex-col gap-y-4 w-full focus:border-brand-yellow"
              >
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Naam*"
                  className="w-full rounded-full px-4 py-2"
                  required={true}
                />
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="E-mailadres*"
                  className="w-full rounded-full px-4 py-2"
                  required={true}
                />
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  placeholder="Telefoonnummer"
                  className="w-full rounded-full px-4 py-2"
                  pattern="(?:7|0\d|\+94\d)\d{8}"
                />
                <textarea
                  name="message"
                  id="message"
                  placeholder="Uw bericht..."
                  className="w-full rounded-xl px-4 py-2"
                  rows={5}
                />
                <div className="w-fit self-end">
                  <input
                    type="submit"
                    value="Verstuur"
                    className="text-white hover:bg-brand-blue-hover bg-brand-blue rounded-full px-8 py-4 lg:px-10 lg:py-3 inline-block"
                  ></input>
                </div>
              </form>
            ) : (
              <>
                {status === 'success' && (
                  <div className="w-full flex justify-center flex-col items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-1/2 text-green-500"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    <p>
                      Bericht is verstuurd, we zullen zo snel mogelijk contact
                      met u opnemen.
                    </p>
                  </div>
                )}
                {status === 'error' && (
                  <div className="w-full flex justify-center flex-col items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-1/2 text-red-500"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                      />
                    </svg>

                    <p>
                      Er is iets fout gegaan met het versturen van dit
                      formulier. Probeer het later nog eens.
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="w-full max-w-sm flex flex-col">
            <h1 className="text-3xl font-bold mb-4 text-gray-800">
              Snelle links
            </h1>
            {settings.links && (
              <div className="flex flex-col gap-y-4 text-black-400">
                {settings.links.map((item) => (
                  <a
                    className="hover:underline underline-offset-4"
                    href={item.link}
                    key={item._key}
                  >
                    {item.title}
                  </a>
                ))}
              </div>
            )}
          </div>
        </div>
        <p className="text-gray-600 z-10">
          <>&copy; Shift Innovatie, {year} | KvK-nummer: 51212951</>
        </p>
      </div>
    </div>
  );
};

export default Footer;
