import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import sanityClient from '../../client';
import PortableText from '../../components/portableText/PortableText';
import { urlFor } from '../../image-url-builder';

export type BlogData = {
  title: string;
  slug: string;
  image: any;
  body: any[];
  author: {
    title: string;
  };
  _updatedAt: string;
};
const itemsPerPage = 5;

const Blog = () => {
  const [blogData, setBlogData] = useState([] as BlogData[]);
  const [count, setCount] = useState(0);

  const [searchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get('page') ?? '1');
  const pages = Math.ceil(count / itemsPerPage);

  useEffect(() => {
    const page = currentPage - 1;
    const lower = itemsPerPage * page;
    const upper = itemsPerPage * page + itemsPerPage - 1;

    sanityClient
      .fetch<{ blogPosts: BlogData[]; count: number }>(
        `{'blogPosts': *[_type == 'blogPost']{title, 'slug': slug.current, image, body, author->{ title }, _updatedAt }[${lower}..${upper}], 'count': count(*[_type == 'blogPost'])}`
      )
      .then((data) => {
        setBlogData(data.blogPosts);
        setCount(data.count);
      })
      .catch(console.error);
  }, [searchParams]);

  const pagination = Array.from({ length: pages }, (_, i) => i + 1);
  if (!blogData) return null;

  return (
    <div>
      <div className="w-full max-w-7xl mx-auto mt-20 mb-12">
        <h1 className="text-7xl lg:text-5xl xl:text-8xl text-gray-800 leading-none underline decoration-brand-yellow mb-12 pl-4 xl:pl-0">
          Blog
        </h1>
        <div className="flex flex-col gap-y-16">
          {blogData?.map((blogPost, idx) => {
            return (
              <div
                key={blogPost.slug}
                className={`flex gap-x-4 flex-col lg:flex-row gap-y-6 lg:gap-y-0 p-4 xl:p-0`}
              >
                <img
                  className="h-64 w-auto md:w-full md:object-cover lg:w-auto lg:object-unset"
                  key={blogPost.slug}
                  src={urlFor(blogPost?.image).url()}
                  alt={blogPost.title}
                />
                <div className="flex flex-col gap-y-4">
                  <div>
                    <h1 className="text-5xl">{blogPost.title}</h1>
                    <p className="text-gray-500">
                      {blogPost.author.title} -{' '}
                      {new Date(blogPost._updatedAt).toLocaleString()}
                    </p>
                  </div>
                  <PortableText value={blogPost.body[0]} />
                  <a href={`/blog/${blogPost.slug}`} className="w-fit self-end">
                    <span className="text-white hover:bg-brand-blue-hover bg-brand-blue rounded-md px-8 py-4 lg:px-10 lg:py-6 inline-block">
                      Lees het hele artikel
                    </span>
                  </a>
                </div>
              </div>
            );
          })}
        </div>
        <div className="mt-12 flex justify-center items-center gap-x-4">
          <a
            href={`?page=${currentPage - 1}`}
            className={`${currentPage <= 1 ? 'hidden' : ''}`}
          >
            {'<<'}
          </a>
          {pagination.map((page) => {
            return (
              <a
                key={page}
                href={`?page=${page}`}
                className={`hover:underline ${
                  currentPage === page ? 'font-bold' : ''
                }`}
              >
                {page}
              </a>
            );
          })}
          <a
            href={`?page=${currentPage + 1}`}
            className={`${currentPage === pages ? 'hidden' : ''}`}
          >
            {'>>'}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Blog;
