import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import sanityClient from '../../client';
import PortableText from '../../components/portableText/PortableText';
import { urlFor } from '../../image-url-builder';

export type ProjectData = {
  title: string;
  slug: string;
  image: any;
  body: any[];
  author: {
    title: string;
  };
  _updatedAt: string;
};
const itemsPerPage = 5;

const Projects = () => {
  const [projectsData, setProjectsData] = useState([] as ProjectData[]);
  const [count, setCount] = useState(0);

  const [searchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get('page') ?? '1');
  const pages = Math.ceil(count / itemsPerPage);

  useEffect(() => {
    const page = currentPage - 1;
    const lower = itemsPerPage * page;
    const upper = itemsPerPage * page + itemsPerPage - 1;

    sanityClient
      .fetch<{ projects: ProjectData[]; count: number }>(
        `{'projects': *[_type == 'project']{title, 'slug': slug.current, 'image': company->logo.asset->, body, _updatedAt }[${lower}..${upper}], 'count': count(*[_type == 'project'])}`
      )
      .then((data) => {
        setProjectsData(data.projects);
        setCount(data.count);
      })
      .catch(console.error);
  }, [searchParams]);

  const pagination = Array.from({ length: pages }, (_, i) => i + 1);
  if (!projectsData) return null;

  return (
    <div>
      <div className="w-full max-w-7xl mx-auto mt-20 mb-12">
        <h1 className="text-7xl lg:text-5xl xl:text-8xl text-gray-800 leading-none underline decoration-brand-yellow mb-12 pl-4 xl:pl-0">
          Projecten
        </h1>
        <div className="flex flex-col gap-y-16 items-end">
          {projectsData?.map((project) => {
            return (
              <div
                key={project.slug}
                className={`flex w-full gap-x-4 flex-col lg:grid lg:grid-cols-5 gap-y-6 lg:gap-y-0 p-4 xl:p-0`}
              >
                {project.image && (
                  <div className="col-span-1 w-full h-full">
                    <img
                      className="h-64 w-auto md:w-full mx-auto object-contain lg:w-auto lg:object-contain object-center"
                      key={project.slug}
                      src={urlFor(project?.image).url()}
                      alt={project.title}
                    />
                  </div>
                )}
                <div className="flex flex-col gap-y-4 lg:col-span-4 lg:col-start-2">
                  <div>
                    <h1 className="text-5xl">{project.title}</h1>
                    <p className="text-gray-500">
                      {new Date(project._updatedAt).toLocaleDateString()}
                    </p>
                  </div>
                  <PortableText value={project.body[0]} />
                  <a
                    href={`/projects/${project.slug}`}
                    className="w-fit self-end"
                  >
                    <span className="text-white hover:bg-brand-blue-hover bg-brand-blue rounded-md px-8 py-4 lg:px-10 lg:py-6 inline-block">
                      Lees het hele artikel
                    </span>
                  </a>
                </div>
              </div>
            );
          })}
        </div>
        <div className="mt-12 flex justify-center items-center gap-x-4">
          <a
            href={`?page=${currentPage - 1}`}
            className={`${currentPage <= 1 ? 'hidden' : ''}`}
          >
            {'<<'}
          </a>
          {pagination.map((page) => {
            return (
              <a
                key={page}
                href={`?page=${page}`}
                className={`hover:underline ${
                  currentPage === page ? 'font-bold' : ''
                }`}
              >
                {page}
              </a>
            );
          })}
          <a
            href={`?page=${currentPage + 1}`}
            className={`${currentPage === pages ? 'hidden' : ''}`}
          >
            {'>>'}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Projects;
