import { useParams } from 'react-router';

const Catch = () => {
  const { slug } = useParams();
  return (
    <div>
      <div className="w-full max-w-7xl mx-auto">
        <h1>Catch: {slug}</h1>
      </div>
    </div>
  );
};

export default Catch;
