import { useEffect, useState } from 'react';
import Splash from '../../components/splash';

import sanityClient from '../../client';
import { SplashProps } from '../../components/splash/Splash';
import About, { AboutProps } from '../../components/about/About';
import Services, { ServicesProps } from '../../components/services/Services';
import Consultants, {
  ConsultantsProps,
} from '../../components/consultants/Consultants';
import Customers, { CustomersProps } from '../../components/customers';

type HomeData = {
  hero: SplashProps;
  about_us: AboutProps;
  services: ServicesProps;
  consultants: ConsultantsProps;
  customers: CustomersProps;
  board: ConsultantsProps;
};

const Home = () => {
  const [homeData, setHomeData] = useState({} as HomeData);

  useEffect(() => {
    sanityClient
      .fetch<HomeData>(
        `*[_id == 'home']
        {
          hero {
            title,
            text,
            'image': image.asset->{
              url
            },
            cta {
              link,
              title
            }
          },
          about_us {
            body,
            title,
            'image': image.asset->{
              url
            },
            usps[] {
                _key,
                text,
                title,
                'icon': icon.asset-> {
                  url
                }
              }
          },
          services {
            title,
            text,
            services[]->{
              title,
              body,
              projects[]->{
                title,
                body,
                'slug': slug.current,
                company->{
                  title,
                  'logo': logo.asset->.url,
                }
              }
            }
          },
          consultants {
            title,
            consultants[]->{
              'image': photo.asset->,
              title,
              subtitle,
              body,
              phone,
              email,
              linkedin
            }
          },
          customers {
            title,
            text,
            customers[]->{
              title,
              body,
              'slug': slug.current,
              company->{
                title,
                'logo': logo.asset->.url,
              }
            }
          },
          board {
            title,
            text,
            'consultants': board_members[]->{
              'image': photo.asset->,
              title,
              subtitle,
              body,
              phone,
              email,
              linkedin
            }
          }
        }
        [0]`
      )
      .then((data) => setHomeData(data))
      .catch(console.error);
  }, []);

  if (!homeData) return null;

  return (
    <div>
      <Splash {...homeData.hero} />
      <div className="w-full max-w-7xl mx-auto">
        <About {...homeData.about_us} />
        <Services {...homeData.services} />
        <Consultants {...homeData.consultants} />
        <Customers {...homeData.customers} />
        <Consultants {...homeData.board} />
      </div>
    </div>
  );
};

export default Home;
