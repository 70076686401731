import { PortableTextProps } from '@portabletext/react';
import { useState } from 'react';
import { urlFor } from '../../image-url-builder';
import Consultant from './Consultant';

export type ConsultantsProps = {
  title: string;
  text?: string;
  consultants: {
    image: string;
    title: string;
    subtitle: string;
    body: PortableTextProps['value'];
  }[];
};

const Consultants = ({ title, consultants, text }: ConsultantsProps) => {
  const [showModal, setShowModal] = useState(false);
  const [consultant, setConsultant] = useState({});

  if (!consultants) return null;

  return (
    <div
      className="w-full flex justify-center items-center text-gray-800 my-12"
      id="consultants"
    >
      <div className="flex flex-col items-center gap-y-8 p-4">
        <h1 className="text-5xl text-gray-800 leading-none mx-auto">
          {title ?? ''}
        </h1>
        {text && (
          <div className="flex items-center text-justify lg:text-center">
            {text}
          </div>
        )}
        <div className="flex flex-wrap justify-center gap-y-8 md:gap-x-10 lg:gap-y-12 gap-x-2 lg:gap-x-20">
          {consultants?.map((consultant, idx) => {
            return (
              <button
                key={consultant.title + idx}
                className="w-full h-full basis-5/12 md:basis-1/3 lg:basis-1/5 aspect-square rounded-full relative overflow-hidden group"
                onClick={() => {
                  setShowModal(true);
                  setConsultant(consultant);
                }}
              >
                <div className="w-full h-full flex justify-center items-center group-hover:bg-black/30 absolute left-0 top-0 transition flex-col">
                  <h1 className="text-3xl text-white opacity-0 group-hover:opacity-100">
                    {consultant.title}
                  </h1>
                  <p className="text-white opacity-0 group-hover:opacity-100 text-light">
                    Leer meer
                  </p>
                </div>
                {consultant.image && (
                  <img
                    className="rounded-full w-full"
                    key={consultant.title}
                    src={urlFor(consultant?.image).size(512, 512).url()}
                    alt={consultant.title}
                  />
                )}
              </button>
            );
          })}
        </div>
      </div>
      <Consultant
        showModal={showModal}
        consultant={consultant}
        onClose={() => {
          setShowModal(false);
          setConsultant({});
        }}
      />
    </div>
  );
};

export default Consultants;
