import { Tab } from '@headlessui/react';
import { PortableTextProps } from '@portabletext/react';
import PortableText from '../portableText/PortableText';
import { Fragment } from 'react';

export type ServicesProps = {
  title: string;
  text: string;
  services: {
    title: string;
    body: PortableTextProps['value'];
    projects: {
      title: string;
      date: string;
      slug: string;
      company: {
        title: string;
        logo: string;
      };
    }[];
  }[];
};

const Services = ({ title, text, services }: ServicesProps) => {
  return (
    <div
      className="w-full flex justify-center items-center text-gray-800 my-12"
      id="services"
    >
      <div className="flex flex-col items-center gap-y-8 p-4">
        <h1 className="text-5xl text-gray-800 leading-none mx-auto">
          {title ?? ''}
        </h1>
        <div className="flex items-center text-justify lg:text-center">
          {text ?? ''}
        </div>
        <div className="flex flex-col lg:flex-row gap-y-8 justify-between w-full gap-x-24">
          <Tab.Group defaultIndex={0}>
            <Tab.List className="flex flex-col w-full lg:w-96 gap-y-4">
              {services?.map((service) => {
                return (
                  <Tab key={service.title} as={Fragment}>
                    {({ selected }) => (
                      <div
                        className={`${
                          selected ? 'bg-brand-blue ' : 'bg-white'
                        } border-2 border-brand-blue-hover rounded-xl py-1 px-8 hover:cursor-pointer text-2xl`}
                      >
                        <h1
                          className={`${
                            selected ? 'text-brand-yellow' : 'text-gray-800'
                          }`}
                        >
                          {service.title}
                        </h1>
                      </div>
                    )}
                  </Tab>
                );
              })}
            </Tab.List>
            <Tab.Panels className="flex flex-1">
              {services?.map((service) => {
                return (
                  <Tab.Panel className="w-full" key={service.title}>
                    <div className={`w-full`}>
                      <h1 className="text-3xl">{service.title}</h1>
                      <PortableText value={service.body} />
                      <div className="grid grid-cols-2 lg:grid-cols-5 w-full gap-x-10 items-center">
                        {service.projects.map((project) => {
                          return project.company ? (
                            <a
                              key={project.slug}
                              href={`/projects/${project.slug}`}
                            >
                              <img
                                key={project.company.title}
                                src={project.company.logo}
                                alt={project.company.title}
                                className="w-full object-contain h-32 lg:h-20"
                              />
                            </a>
                          ) : undefined;
                        })}
                      </div>
                    </div>
                  </Tab.Panel>
                );
              })}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </div>
  );
};

export default Services;
