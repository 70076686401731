import { PortableTextProps } from '@portabletext/react';
import PortableText from '../portableText/PortableText';

export type AboutProps = {
  body: PortableTextProps['value'];
  title: string;
  image: {
    url: string;
  };
  usps: {
    icon: {
      url: string;
    };
    _key: string;
    title: string;
    text: string;
  }[];
};

const About = ({ image, body, title, usps }: AboutProps) => {
  return (
    <div
      className="w-full flex justify-center items-center text-gray-800 my-12"
      id="about"
    >
      <div className="flex flex-col items-center gap-y-8 p-4">
        <h1 className="text-5xl text-gray-800 leading-none mx-auto">
          {title ?? ''}
        </h1>
        <div className="flex flex-col lg:flex-row  gap-y-8 lg:gap-y-0 lg:gap-x-8 items-center text-justify lg:text-left">
          <img src={image?.url} alt="about us" className="w-full lg:w-1/3" />
          <PortableText value={body} />
        </div>
        <div className="w-full grid lg:grid-cols-3 lg:grid-rows-2 grid-cols-1 grid-rows-6 gap-6">
          {usps?.map((usp) => {
            return (
              <div key={usp?._key} className="flex gap-x-4">
                <img
                  className="w-12 object-contain"
                  src={usp?.icon?.url}
                  alt={usp.title ?? ''}
                />
                <div className="flex flex-col justify-center">
                  <h1>{usp.title ?? ''}</h1>
                  <p className="text-sm">{usp.text ?? ''}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default About;
