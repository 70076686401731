import { PortableTextReactComponents } from '@portabletext/react';
import { urlFor } from '../../image-url-builder';

const PortableTextComponents: Partial<PortableTextReactComponents> = {
  marks: {
    annotationLinkExternal: ({ children, value }) => {
      return (
        <a
          href={value.url}
          className="underline"
          target={value.newWindow ? '_blank' : '_self'}
          rel="noreferrer noopener"
        >
          {children}
        </a>
      );
    },
  },
  types: {
    blockImages: ({ value }) => (
      <img
        className="h-64 w-full object-cover"
        src={urlFor(value).url()}
        alt={value.alt}
      />
    ),
  },
};

export default PortableTextComponents;
