import { useState, Fragment } from 'react';
import { Squash as Hamburger } from 'hamburger-react';
import { Dialog, Transition } from '@headlessui/react';

import { Settings } from '../../App';

type HeaderProps = {
  settings: Settings['header'];
};

const Header = ({ settings }: HeaderProps) => {
  const [isOpen, setIsOpen] = useState(false);

  if (!settings) return null;

  return (
    <div className="w-screen h-16 bg-brand-yellow p-3 flex justify-center fixed top-0 left-0 shadow z-30">
      <div className="flex justify-between w-full h-full max-w-7xl">
        {settings.logo?.url && (
          <a href="/" className="h-full">
            <img
              src={settings.logo.url}
              alt="Shift Innovatie"
              className="h-full object-contain"
            />
          </a>
        )}

        {settings.links && (
          <div className="flex-1 h-full gap-x-12 justify-end items-center text-black-400 hidden lg:flex">
            {settings.links.map((item) => (
              <a
                className="hover:underline underline-offset-4 text-gray-700"
                href={item.link}
                key={item._key}
              >
                {item.title}
              </a>
            ))}
          </div>
        )}

        <div className="lg:hidden h-full flex items-center">
          <div className="z-40">
            <Hamburger
              color="#374151"
              distance="sm"
              size={24}
              rounded={true}
              toggled={isOpen}
              toggle={setIsOpen}
            />
          </div>
          <Transition show={isOpen} as={Fragment}>
            <Dialog
              open={isOpen}
              onClose={() => {
                setTimeout(() => setIsOpen(false), 1);
              }}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
              </Transition.Child>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-500"
                enterFrom="-right-full"
                enterTo="right-0"
                leave="ease-in duration-300"
                leaveFrom="right-0"
                leaveTo="-right-full"
              >
                <Dialog.Panel className="fixed top-16 right-0 h-mobile-menu w-full md:max-w-md shadow-lg bg-white z-10">
                  {settings.links && (
                    <div className="flex flex-col w-full h-full px-8 py-16 gap-y-12 text-black-400 ">
                      {settings.links.map((item) => (
                        <a
                          className="hover:underline underline-offset-4 text-gray-700 text-3xl"
                          href={item.link}
                          key={item._key}
                        >
                          {item.title}
                        </a>
                      ))}
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </Dialog>
          </Transition>
        </div>
      </div>
    </div>
  );
};

export default Header;
