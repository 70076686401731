import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { urlFor } from '../../image-url-builder';
import PortableText from '../portableText/PortableText';

export type ConsultantProps = {
  showModal: boolean;
  consultant: any;
  onClose: () => void;
};

const Consultant = ({ showModal, onClose, consultant }: ConsultantProps) => {
  return (
    <Transition show={showModal} as={Fragment}>
      <Dialog open={showModal} onClose={onClose} className="relative z-50">
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <Dialog.Panel className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 shadow-lg bg-white p-8 rounded-lg w-full lg:w-full max-w-4xl">
          <div className="w-full h-full flex flex-col gap-y-4 lg:gap-y-0 lg:flex-row items-center relative gap-x-20">
            <button onClick={onClose} className="absolute top-0 right-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <div className="w-full aspect-square">
              {consultant.image && (
                <img
                  className="rounded-full h-full w-full aspect-square"
                  key={consultant.title}
                  src={urlFor(consultant.image).size(512, 512).url()}
                  alt={consultant.title}
                />
              )}
            </div>
            <div className="flex flex-col gap-y-10">
              <div>
                <h1 className="text-3xl">{consultant.title}</h1>
                <h2>{consultant.subtitle}</h2>
                <PortableText value={consultant.body ?? []} />
              </div>
              <div className="text-sm text-gray-400 flex flex-col gap-y-1">
                {consultant.email && (
                  <a href={`mailto:${consultant.email}`}>{consultant.email}</a>
                )}
                {consultant.phone && <p>{consultant.phone}</p>}
                {consultant.linkedin && (
                  <a
                    href={consultant.linkedin.url}
                    target="_blank"
                    rel="noreferrer noopener"
                    className=""
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      className="h-4 fill-brand-blue"
                    >
                      <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                    </svg>
                  </a>
                )}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </Transition>
  );
};

export default Consultant;
