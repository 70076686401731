export type SplashProps = {
  title: string;
  text: string;
  image: {
    url: string;
  };
  cta: {
    link: string;
    title: string;
  };
};

const Splash = ({ title, text, cta, image }: SplashProps) => {
  return (
    <div className="h-mobile-menu w-full flex items-center justify-between relative overflow-hidden">
      <div className="p-4 lg:p-0 lg:w-3/4 flex-col-reverse lg:flex-row flex mx-auto">
        <div className="lg:w-2/5 gap-y-4 lg:gap-y-8 flex-col flex">
          <h1 className="text-5xl xl:text-8xl text-gray-800 leading-none">
            {title ?? ''}
          </h1>
          <p className="text-sm lg:text-base text-gray-700">{text ?? ''}</p>
          <a href={cta?.link ?? '/'} className="w-fit ">
            <span className="text-white hover:bg-brand-blue-hover bg-brand-blue rounded-md px-8 py-4 lg:px-10 lg:py-6 inline-block">
              {cta?.title ?? ''}
            </span>
          </a>
        </div>
        {image && (
          <img
            src={image.url}
            className="w-full h-full object-contain lg:w-2/3 lg:absolute lg:top-1/2 lg:-translate-y-1/2 lg:-right-16"
            alt="splash"
          />
        )}
      </div>
    </div>
  );
};

export default Splash;
