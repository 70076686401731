export type CustomersProps = {
  title: string;
  text: string;
  customers: {
    title: string;
    date: string;
    slug: string;
    company: {
      title: string;
      logo: string;
    };
  }[];
};

const Customers = ({ title, text, customers: projects }: CustomersProps) => {
  return (
    <div
      className="w-full flex justify-center items-center text-gray-800 my-12"
      id="customers"
    >
      <div className="flex flex-col items-center gap-y-8 p-4">
        <h1 className="text-5xl text-center text-gray-800 leading-none mx-auto">
          {title ?? ''}
        </h1>
        {text && (
          <div className="flex items-center text-justify lg:text-center">
            {text}
          </div>
        )}
        <div className="w-full grid grid-cols-2 gap-y-8 lg:gap-y-0 lg:grid-cols-4 place-items-center gap-x-16">
          {projects?.map((project) => {
            return (
              <a href={`/projects/${project.slug}`} key={project.title}>
                <img
                  className=""
                  key={project.company.title}
                  src={project.company.logo}
                  alt={project.company.title}
                />
              </a>
            );
          })}
        </div>
        <a href={'/projects'} className="w-fit ">
          <span className="text-white hover:bg-brand-blue-hover bg-brand-blue rounded-md px-8 py-4 lg:px-10 lg:py-6 inline-block">
            Zie alle projecten
          </span>
        </a>
      </div>
    </div>
  );
};

export default Customers;
